import React from "react";

export default function Gallery() {
  return (
    <>
      <section id="page-banner" class="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="page-banner-cont">
                <h2>Image Gallery</h2>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Gallery</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-90 pb-120 gray-bg">
        <div class="container">
          <div class="row gallery">
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/a.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/a.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/g.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/g.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/c.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/c.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/d.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/d.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/e.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/e.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/f.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/f.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/b.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/b.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/h.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/h.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/i.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/i.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/j.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/j.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/k.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/k.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>

            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/l.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/l.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/m.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/m.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/n.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/n.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/o.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/o.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/p.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/p.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/q.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/q.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/r.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/r.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
            <div class="col-md-4 col-xs-6 thumb">
              <a href="assets/images/school/s.jpg">
                <figure><img class="img-fluid img-thumbnail" src="assets/images/school/s.jpg" style={{ height: "250px", width: "370px" }} alt="Gallery Image" /></figure>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}