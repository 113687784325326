import React from 'react';

export default function Home() {
  return (
    <>
      <section id="slider-part" className="slider-active ">
        <div className="single-slider bg_cover pt-150" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }} data-overlay="4">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9">
                <div className="slider-cont">
                  <h1 data-animation="fadeInLeft" data-delay="1s">Welcome To Chanakya International School</h1>
                  <p data-animation="fadeInUp" data-delay="1.3s"></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="single-slider bg_cover pt-150" style={{ backgroundImage: "url(assets/images/school/ab.jpg)" }} data-overlay="4">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9">
                <div className="slider-cont">
                  <h1 data-animation="fadeInLeft" data-delay="1s">Welcome To Chanakya International School</h1>
                  <p data-animation="fadeInUp" data-delay="1.3s"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-slider bg_cover pt-150" style={{ backgroundImage: "url(assets/images/school/bs.jpg)" }} data-overlay="4">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-9">
                <div className="slider-cont">
                  <h1 data-animation="fadeInLeft" data-delay="1s">Welcome To Chanakya International School</h1>
                  <p data-animation="fadeInUp" data-delay="1.3s"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="about-page" className="pt-70 pb-110">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-title mt-50">
              <h2><strong>About <span style={{color:"red"}}> School</span></strong></h2><br></br>
                <p>Chanakya International School is situated in Manu ka pura , Uruwa, Meja at Mirzapur road NH
                35. It was felt need to establish an English medium school in this very locality, where a child
                gets the chance for total development of his/her personality, without any discrimination of caste,
                creed or culture.
                  Chanakya International School is a co-educational English medium school established and
                  administered under the aegis of <strong> Rama Memorial Foundation </strong>. Currently we are open to accept
                  applications for classes from ‘nursery to class V’, in academic year 2021-22 and it will be full
                  pledged school upto class XII.</p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="about-image mt-50">
                <img src="assets/images/school/front.jpg"  alt="About" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}