import React from "react";

export default function About() {

    return (
        <>
            <section id="page-banner" className="pt-105 pb-110 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="page-banner-cont">
                                <h2>About Us</h2>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about-page" className="pt-70 pb-110">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="section-title mt-50">
                                <h2><strong>About <span style={{ color: "red" }}> Chanakya International School</span></strong></h2>
                            </div>
                            <div className="about-cont">
                                <p align="justify">Chanakya International School is situated in Manu ka pura , Uruwa, Meja at Mirzapur road NH
                                    35. It was felt need to establish an English medium school in this very locality, where a child
                                    gets the chance for total development of his/her personality, without any discrimination of caste,
                                    creed or culture.
                                    Chanakya International School is a co-educational English medium school established and
                                    administered under the aegis of <strong> Rama Memorial Foundation </strong>. Currently we are open to accept
                                    applications for classes from ‘nursery to class V’, in academic year 2021-22 and it will be full
                                    pledged school upto class XII.</p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="about-image mt-50">
                                <img src="assets/images/school/ab.jpg" alt="About" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}