import React from "react";
import { BrowserRouter as Router, Routes, Route }
  from 'react-router-dom';
import About from "./components/About";
import Footer from "./components/Includes/Footer";
import Gallery from "./components/MediaSection/Gallery";
import Header from "./components/Includes/Header";
import Home from "./components/Home";
import DirectorMsg from "./components/Message/DirectorMsg";
import AdministratorMsg from "./components/Message/AdministratorMsg";
import PrincipleMsg from "./components/Message/PrincipalMsg";
import Contact from "./components/Contact";
import MediaGallery from "./components/MediaSection/MediaGallery";
import VideoGallery from "./components/MediaSection/VideoGallery";
import OurVission from "./components/OurVission";
import Recommendation from "./components/Recommendation";
import RulesRegulations from "./components/RulesRegulations";
import Admission from "./components/Admission";
import MandatoryDisclosure from "./components/MandatoryDisclosure";
import CopiesOfAffiliation from "./components/CopiesOfAffiliation";

export default function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path='/about-us' element={<About />} />
          <Route exact path='/gallery' element={<Gallery />} />
          <Route exact path='/Media-gallery' element={<MediaGallery />} />
          <Route exact path='/video-gallery' element={<VideoGallery />} />
          <Route exact path='/director-msg' element={<DirectorMsg />} />
          <Route exact path='/administrator-msg' element={<AdministratorMsg />} />
          <Route exact path="/principle-msg" element={<PrincipleMsg />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/our-vission" element={<OurVission />} />
          <Route exact path="/recommendation" element={<Recommendation />} />
          <Route exact path="/rules-regulations" element={<RulesRegulations />} />
          <Route exact path="/admission" element={<Admission />} />
          <Route exact path="/mandatory-disclosure" element={<MandatoryDisclosure />} />
          <Route exact path="/Copies-Of-Affiliation" element={<CopiesOfAffiliation />} />
        </Routes>
        <Footer />
      </Router>


    </div>
  );
}