import React from "react";
import { Link, UseNavigate } from "react-router-dom";



export default function Header() {
    return (
        <>
            <header id="header-part" >
                <div className="navigation">
                    <div className="container" >
                        <div className="row">
                            <div className="col-lg-12">
                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand" href="/">
                                        <img src="assets/images/school/new.jpg" alt="Logo" />
                                    </a>
                                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                        <span class="icon-bar"></span>
                                    </button>
                                    <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                        <ul className="navbar-nav ml-auto">
                                            <li className="nav-item">
                                                <a className="#" href="/">Home</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/about-us">About Us</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/admission">Admission</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#">Message</a>
                                                <ul className="sub-menu">
                                                    <li><a href="/director-msg">Director's Message</a></li>
                                                    <li><a href="/administrator-msg">Administrator Message</a></li>
                                                    <li><a href="/principle-msg">Principal's Message</a></li>
                                                </ul>
                                            </li>

                                            <li className="nav-item">
                                                <a href="#">Gallery</a>
                                                <ul className="sub-menu">
                                                    <li><a href="/gallery">Photo Gallery</a></li>
                                                    <li><a href="/video-gallery">Video Gallery</a></li>
                                                    <li><a href="/media-gallery">Media Gallery</a></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/mandatory-disclosure">Mandatory Disclosure</a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="/contact-us">Contact Us</a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>


        </>
    );
}