import React from "react";
import emailjs from "emailjs-com";

export default function Contact() {
  // function sendEmail(e) {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_4svcpzm', 'template_h498wfj', e.target, 'k401aHBXD_LzdKCHf')
      .then(res => { console.log("Message sent Successfully", res.status, res.text); })
      .catch(err => console.log("Failed", err));
  }
  return (
    <>
      <section id="page-banner" class="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="page-banner-cont">
                <h2>Contact Us</h2>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Contact us</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-page" className="pt-50 pb-50 gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <div className="contact-from">
                <div className="section-title">
                  <h2><strong>Mail <span style={{ color: "red" }}>Us</span></strong></h2>
                </div>
                <div className="main-form pt-45">
                  <form onSubmit={sendEmail} method="POST" >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="single-form form-group">
                          <input name="name" type="text" placeholder="Your name" data-error="Name is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-form form-group">
                          <input name="email" type="email" placeholder="Email" data-error="Valid email is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-form form-group">
                          <input name="subject" type="text" placeholder="Subject" data-error="Subject is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="single-form form-group">
                          <input name="phone" type="text" placeholder="Phone" data-error="Phone is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <textarea name="message" placeholder="Message" data-error="Please,leave us a message." required="required"></textarea>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form">
                          <button type="submit" className="main-btn">Send</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-address">
                <div className="contact-heading">
                  <h3>Address</h3><br />
                  <p>If you have any further questions, please don’t hesitate to contact me.</p>
                </div>
                <ul>
                  <li>
                    <div className="single-address">
                      <div className="icon">
                        <i className="fa fa-home"></i>
                      </div>
                      <div className="cont">
                        <p>  Manukapura, Uruwa, Mejaroad, Prayagraj Uttar Pradesh 212302</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="single-address">
                      <div className="icon">
                        <i className="fa fa-phone"></i>
                      </div>
                      <div className="cont">
                        <p>+91-9415141818</p>
                        <p>+91-9415111818</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="single-address">
                      <div className="icon">
                        <i className="fa fa-envelope-o"></i>
                      </div>
                      <div className="cont">
                      <a href="mailto: info@cisprayagraj.com">info@cisprayagraj.com</a>
                        <p>sudhashru@gmail.com</p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="single-address">
                      <div className="icon">
                        <i className="fa fa-globe"></i>
                      </div>
                      <div className="cont">
                        <p>cis.vapsoft.org</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-page" className="pt-50 pb-50 gray-bg">
        <div className="container">
          <div className="row mb-10">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.3867834383964!2d82.14043391432712!3d25.190175638168427!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3985594e8ad38493%3A0x84315fed77301a50!2sChanakya%20International%20School!5e0!3m2!1sen!2sin!4v1669102170430!5m2!1sen!2sin" width="1200" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </section>
    </>
  );
}