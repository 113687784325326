import React from 'react';

export default function DirectorMsg() {
  return (
    <>
      <section id="page-banner" className="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner-cont">
                <h2>Message</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Director's Message</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="teachers-single" className="pt-70 pb-80 gray-bg">
        <div className="row justify-content-center">
          <h2><strong>Director's <span style={{ color: "red" }}>Message</span></strong></h2><br></br>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="teachers-right mt-50">
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div className="dashboard-cont">
                      <div className="col-lg-6 col-md-10">
                        <div className="teachers-left ">
                          <div className="hero">
                            <img src="assets/images/school/principle.JPG" alt="Teachers" />
                          </div>
                        </div>
                      </div>
                      <ul><br />
                        <li className="text-justify">We Chanakya International School is established in memory
                          of my respected Grande father (Late Ram Sundar Mishra)
                          and respected father (Late Ram Abhilash Mishra), both were
                          Govt. teacher and dreamed to enrich their motherland by
                          providing the best class education to upcoming generation.
                          That is the core need for developing an ideal society. </li><br />
                        <li className='text-justify'>To fulfill their dreams we are here to provide best class of
                          education to deserving pupils and to up bring their hidden
                          talent with the help of extra curriculum activities in your
                          locality. </li><br />
                        <li className='text-justify'> As per the current situation most of the people leave their
                          native place and migrated to city, only for better education of
                          their kids. And we know what the problems we face after
                          migration. In few cases we have observed that for better education, student live alone and struggle
                          for better livelihood without parents. And how important parenting is, we know. Hopefully we will
                          overcome these challenges of society. </li><br />
                        <li className='text-justify'> So we both will be on the same page: children, teachers and parents have to understand that
                          education is not restricted to lessons taught in the classroom from text books. Parents and teachers
                          must encourage children to ask as many questions as they can. For it is in wanting to know that will
                          result in actually getting to know. </li><br />
                        <li className='text-justify'> The thirst for knowledge is not restricted to the child. It is also essential for a parent and a teacher to
                          keep adding to their own knowledge bank by keeping their eyes and ears open and by reading as
                          much as possible and ensuring that children develop these habits as well.</li><br />
                      </ul>
                      <div className="col-lg-12 pb-80">
                        <div className=" mt-20" style={{ float: "right" }}>
                          <p><strong> Akhilesh Kumar Mishra</strong></p>
                          <p><strong> 'Director'</strong></p>
                          <p><strong>Chanakya International School</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}