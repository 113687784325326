import React from 'react';

export default function PrincipalMsg() {
  return (
    <>
      <section id="page-banner" className="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner-cont">
                <h2>Message</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Principal's Message</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="teachers-single" className="pt-70 pb-120 gray-bg">
        <div className="container">
          <div className="row justify-content-center">
            <h2><strong>Principal's <span style={{ color: "red" }}>Message</span></strong></h2><br></br>
            <div className="col-lg-10">
              <div className="teachers-right mt-50">
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div className="dashboard-cont">
                      <ul><br />
                        <li className="text-justify">With a new session, a new beginning and things will change. When Jawaharlal Nehru addressed the nation for the first time, little did we know that in a short span of about 70 years, our country will move forward to become one of the fastest growing economies and the most promising countries of the world.</li><br />
                        <li className='text-justify'>History is testimony to the fact that John F. Kennedy believed in new beginnings. His conviction that man had to tread the space for gaining knowledge of the far and beyond, at a time when space travel was unthought-of.</li><br />
                        <li className='text-justify'> With the turn of the calendar, the commitment to take the school to greater heights is more firmly established. New energies, new blood pumped into the system brings a fresh thought and a paradigm shift is being envisaged. Undoubtedly, this would require hard work, perseverance and a firm belief in the adage ‘Yes, We Can’, but we are committed and with a firm resolve, would surge forth to accomplish the vision of our preceding stalwarts who set the tone and tempo for performance.</li><br />
                        <li className='text-justify'> Any institution works smoothly and attains recognition and its place on the ladder with team-work and mutual trust. The parent body is an integral part of this trajectory. Their continued involvement, collaboration and motivation to the student community will lead to the accomplishment of personal as well as school goals. Newer and more innovative ways of collaboration need to be evolved and implemented. To attain this, the institution is committed.</li><br />
                        <li className='text-justify'> To the students, I have but one message to give. Dream big and work towards your goal. We, at Chanakya International School Meja Prayagraj, strive to see your beginnings culminate in unparalleled success. And, success comes to those who are good human beings while at the same time excel in scholastic as well as co-scholastic fields. You stepped into the portals of this great institution with fresh thoughts, lofty aims, high hopes, and exalted aspirations. I assure you that your ambitions will be accomplished through the able guidance of the highly accomplished and experienced faculty of teachers. In return, the school seeks superior ethics, positive attitude, social and moral values and discipline. Remember, you are the torchbearers and your first and foremost duty is to follow the footsteps of the trailblazer alumni yet chart a unique course for yourself.</li><br />
                        <li className='text-justify'> To conclude, I would like to reiterate my belief with this quote by Henry Ford: If everyone is moving forward together, then success takes care of itself. So let’s all join hands to take off on this journey to higher orbits of excellence.</li><br />
                        <li className='text-justify'>With best wishes to all </li><br />
                      </ul>
                      <div className="col-lg-12 pb-80">
                        <div className=" mt-20" style={{ float: "right" }}>
                          <p><strong> Principal</strong></p>
                          <p><strong>Chanakya International School</strong></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}