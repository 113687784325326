import React from 'react';

export default function AdministratorMsg() {
  return (
    <>
      <section id="page-banner" className="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner-cont">
                <h2>Message</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Administrator's Message</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="teachers-single" className="pt-70 pb-120 gray-bg">
        <div className="row justify-content-center">
          <h2><strong>Administrator's <span style={{ color: "red" }}>Message</span></strong></h2><br></br>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-8">
              <div className="teachers-left mt-50">
                <div className="hero">
                  <img src="assets/images/school/admin.jpg" alt="Teachers" />
                  <div class="name">
                    <h6>Er.Sudhashu Mishra</h6><br></br>
                    <span>School Administrator</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="teachers-right mt-50">
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    <div className="dashboard-cont">
                      <div className="col-lg-12"><br />
                        <p><strong>Dear students, parents and
                          well wishers, “Intelligence
                          plus character- That is the
                          goal of true education”
                          (Martin Luther King Jr.) </strong></p>
                      </div>
                    </div>
                    <ul><br />
                      <li className="text-justify">Children are the centre of universe, their welfare and happiness must be our ultimate goal.
                        Education is a tool that can empower our children to change the destiny of a nation. We felt the
                        need to have this right kind of education at Prayagraj that provides global outlook with roots firmly
                        embedded in our culture. </li><br />
                      <li className='text-justify'>Chanakya International School Meja, Prayagraj under the aegis of Rama Memorial Foundation has
                        a legacy of its own in the field of education, it will not only provide quality education par excellence
                        but will also create a platform for positive, progressive likeminded people to converge and create.
                        The central conception of education in India has been that it is the source of illumination guiding us
                        in all spheres of life. It takes into account the entire sphere of life giving a sense of perspective and
                        proportion. </li><br />
                      <li className='text-justify'> We have set very high goals and standards for our institution .Each and every student will be
                        provided with opportunity to realize his or her potential by focusing on individual differences and
                        right frequency between teacher and the taught. The education imparted must ensure students
                        learn how to think rather than what to think. Education is about the total configuration of ideas,
                        practices and conduct. </li><br />
                      <li className='text-justify'> As Dr. Abdul Kalam said "I have three visions for India, Freedom, Development and Strength. Our
                        students will imbibe these qualities in the conducive environment created at school and train to be
                        competent professionals, responsible citizens and future world leaders." </li><br />
                    </ul>
                    <div className="col-lg-12 pb-80">
                      <div className=" mt-20" style={{ float: "right" }}>
                        <p><strong> Er. Sudhashu Mishra</strong></p>
                        <p><strong> School Administrator</strong></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}