import React from 'react';

export default function OurVission() {
  return (
    <>
      <section id="page-banner" className="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner-cont">
                <h2>Our Vission</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Our Vission</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-105 pb-110">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="privacy-desc">
                <h2><strong>Our  <span style={{ color: "red" }}>Vission</span></strong></h2><br></br>
                <p>Our vision is to provide each ward with the education which will equip them for their future in
                  the society. Our curriculum will also help them in the growth and development of balanced
                  personality which is very essential to be happy and successful in later life. To achieve our aims
                  and objectives, we offer: </p>
              </div>
              <div class="privacy-list mt-10 mb-30">
                <ul>
                  <li>  A pupil centered approach where every learner is provided with opportunities for
                    individual growth.</li>
                  <li>Promote social and national integration by a relevant education for a challenging society </li>
                  <li>Expand and provide easily accessible modes of quality education, </li>
                  <li>Well qualified, professional and dedicated teaching staff.</li>
                  <li>Build up social moral and spiritual values </li>
                  <li> Train with the use of latest educational inputs and technology. </li>
                  <li>Create a better and more peaceful world </li>
                  <li>Extend shared responsibility for learning success and well-being of all students. </li>
                  <li> Reach those groups disadvantaged due to physiological, psychological, educational, socio
                    economic circumstances.</li>
                  <li>Excellent facilities and the latest education technology such as smart classes, computer
                    classes. </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}