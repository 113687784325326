import React from 'react';

export default function RulesRegulations() {
  return (
    <>
      <section id="page-banner" className="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner-cont">
                <h2>Rules and Regulations</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Rules & Regulations</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-105 pb-110">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="privacy-desc">
                <h2><strong>Rule's  & <span style={{ color: "red" }}> Regulation's </span></strong></h2><br></br>
              </div>
              <div class="privacy-list mt-10 mb-30">
                <ul>
                  <li> Students without proper uniform will not be allowed to attend the classes</li>
                  <li>Latecomers will be permitted to enter the school campus with the permission of the
                    Principal only.
                  </li>
                  <li>During school hours students will not be permitted to leave the campus without
                    permission.
                  </li>
                  <li>The Principal may impose fine for any damage caused to the school property.</li>
                  <li>Child can be expelled from the school if he/she shows irregular attendance, negligence of
                    home-work, habitual lack of interest in school work, disobedience and disrespect towards
                    teachers & for any other indisciplinary activity</li>
                  <li> Writing scribbling or spitting on walls or furniture is strictly prohibited. Hefty fine is
                    imposed if any damage is caused to the school property </li>
                  <li>The final decision about expulsion of any student concerning his/her indisciplined
                    behavior will be in the hands of the management.
                  </li>
                  <li>Long finger nails, nail polish, jewelry or bringing of expensive articles, use of mobile,
                    fanciful earrings etc., to the school is prohibited.</li>
                  <li> The school uniform is compulsory on all school days and school functions. Children who
                    come without the school uniform should bring a note of excuse from their parents.
                  </li>
                  <li>All must have at least two pairs of school dress for convenience and to be always clean,
                    neat and tidy. </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}