import React from "react";

export default function MediaGallery() {
  return (
    <>
      <section id="page-banner" class="pt-100 pb-100 bg_cover" data-overlay="8" style={{backgroundImage: "url(assets/images/school/front.jpg)"}}>
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="page-banner-cont">
                <h2>Media Gallery</h2>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Media Gallery</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <h1>Coming Soon...!</h1>

    </>
  );
}