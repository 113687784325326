import React from 'react';

export default function Admission() {
  return (
    <>
      <section id="page-banner" className="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner-cont">
                <h2>Admission</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Admission</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-page" className="pt-50 pb-50 gray-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-md-2">
              <div className="contact-from">
                <div className="section-title">
                  <h2><strong>Admission <span style={{ color: "red" }}>form</span></strong></h2>
                </div>
                <div className="main-form pt-30">
                  <form id="contact-form" action="http://cis.vapsoft.org/" method="post" data-toggle="validator">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <input name="name" type="text" placeholder="Your name" data-error="Name is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <input name="email" type="email" placeholder="Email" data-error="Valid email is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <input name="class" type="text" placeholder="Class" data-error="Class is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <input name="phone" type="text" placeholder="Phone" data-error="Phone is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <input name="address" type="text" placeholder="Address" data-error="Address is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <input name="name" type="text" placeholder="Previous School" data-error="School name is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <input name="name" type="text" placeholder="Father name" data-error="Father name is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form form-group">
                          <input name="name" type="text" placeholder="Mother name" data-error=" Mother name is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12 ">
                        <div className="single-form form-group">
                          <input name="name" type="text" placeholder="Father Occupation" data-error="This Feild is required." required="required" />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="single-form">
                          <button type="submit" className="main-btn">Submit</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

