import React from 'react';

export default function MandatoryDisclosure() {
  return (
    <>
      <section id="page-banner" className="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner-cont">
                <h2>Mandatory Disclosure</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Mandatory Disclosure</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-40 pb-40">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="privacy-desc">
                <h4><strong>GENERAL  <span style={{ color: "red" }}>INFORMATION</span></strong></h4><br></br>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Sr No.</th>
                    <th scope='col'>INFORMATION</th>
                    <th colSpan="2">DETAILS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>NAME OF THE SCHOOL</td>
                    <td colSpan="2">CHANAKYA INTERNATIONAL SCHOOL</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td >AFFILIATION NO.(IF APPLICABLE)</td>
                    <td colSpan="2" >2133994</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>SCHOOL CODE (IF APPLICABLE)</td>
                    <td colSpan="2" > 71993 </td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>COMPLETE ADDRESS WITH PIN CODE</td>
                    <td colSpan="2" > Manukapura, Uruwa, Mejaroad, Prayagraj Uttar Pradesh 212302 </td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>PRINCIPAL NAME</td>
                    <td colSpan="2" >MR. ANTONIO KUMAR </td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>PRINCIPAL QUALIFICATION</td>
                    <td colSpan="2" >M.A, B.ED, M.ED</td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td >SCHOOL EMAIL ID</td>
                    <td colSpan="2" > sudhashru@gmail.com </td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td>CONTACT DETAILS (LANDLINE/MOBILE)</td>
                    <td colSpan="2" > +91-9415141818 <br/> +91-9415111818 </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12">
              <div class="privacy-desc">
                <h4 className='pt-20'><strong>DOCUMENTS   <span style={{ color: "red" }}>AND INFORMATION</span></strong></h4><br></br>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Sl No.</th>
                    <th scope='col'>DOCUMENTS/INFORMATION</th>
                    <th colSpan="2"> UPLOADED DOCUMENTS </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td >COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY
                    </td>
                    <td colSpan="2"><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/grantt_letter.pdf" >View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td >COPIES OF SOCIETIES/TRUST/COMPANY  REGISTRATION/RENEWAL CERTIFICATE, AS APPLICABLE</td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/Societypaper.pdf">View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>COPY OF NO OBJECTION CERTIFICATE (NOC) ISSUED, IF APPLICABLE, BY THE STATE GOVT./UT*
                    </td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/NOC.pdf">View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>COPIES OF RECOGNITION CERTIFICATE UNDER RTE ACT, 2009, AND IT’S RENEWAL IF APPLICABLE*</td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" href="assets/images/Mandatory/Recognitioncertificate.pdf" target="_blank" >View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td >COPY OF VALID BUILDING SAFETY CERTIFICATE AS PER THE NATIONAL BUILDING CODE*</td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/BuildingSafety.pdf">View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td >COPY OF VALID FIRE SAFETY CERTIFICATE ISSUED BY THE COMPETENT AUTHORITY*
                    </td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/FireSafety.pdf">View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td>COPY OF THE DEO CERTIFICATE SUBMITTED BY THE SCHOOL FOR AFFILIATION/UPGRADATION/EXTENSION OF AFFILIATION OR SELF CERTIFICATION BY SCHOOL*</td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/SelfCertification.pdf">View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td >COPIES OF VALID WATER, HEALTH AND SANITATION CERTIFICATES</td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" href="assets/images/Mandatory/WaterHealthCertificate.pdf" target="_blank" >View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td >OTHERS</td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" href="assets/images/Mandatory/TransformerSafety.pdf" target="_blank" >View</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12">
              <div class="privacy-desc">
                <h4 className='pt-20'><strong>RESULT <span style={{ color: "red" }}>AND ACADEMICS</span></strong></h4><br></br>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Sl No.</th>
                    <th scope='col'>DOCUMENTS/INFORMATION </th>
                    <th colSpan="2"> UPLOADED DOCUMENTS </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td >FEE STRUCTURE OF THE SCHOOL
                    </td>
                    <td colSpan="2"><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/Feestructure.pdf">View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>ANNUAL ACADEMIC CALENDER</td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/AnnualAcademicCalendar.pdf">View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td >LIST OF SCHOOL MANAGEMENT COMMITTEE (SMC)
                    </td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/NewDoc.pdf">View</a></td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td >LIST OF PARENTS TEACHERS ASSOCIATION (PTA) MEMBERS*</td>
                    <td colSpan="2" ><a class="btn btn-primary" role="button" target="_blank" href="assets/images/Mandatory/PTA.pdf" >View</a></td>

                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td >LAST THREE-YEAR RESULT OF THE BOARD EXAMINATION AS PER
                      APPLICABILITY</td>
                    <td colSpan="2" >NA</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12">
              <div class="privacy-desc">
                <h4 className='pt-20'><strong>STAFF <span style={{ color: "red" }}> (TEACHING)</span></strong></h4><br></br>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Sl No.</th>
                    <th scope='col'>INFORMATION </th>
                    <th colSpan="2">DETAILS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>PRINCIPAL NAME
                    </td>
                    <td colSpan="2"> MR. ANTONIO KUMAR</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>TOTAL NO. OF TEACHERS*<br></br> PGT <br></br> TGT<br></br> PRT<br></br></td>
                    <td colSpan="2"> 19 <br />0<br />8<br />8<br /></td>
                  </tr>

                  <tr>
                    <th scope="row">3</th>
                    <td>TEACHERS SECTION RATIO
                    </td>
                    <td colSpan="2">1:1.5</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>DETAILS OF SPECIAL EDUCATOR</td>
                    <td colSpan="2" >PANNA LAL YADAV - B.SC, D.ED (Special- H)   </td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td >DETAILS OF COUNSELLOR AND WELLNESS TEACHER*</td>
                    <td colSpan="2" >SUSHIL KUMAR GAUTAM -MA (PSYCHOLOGY) </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12">
              <div class="privacy-desc">
                <h4 className='pt-20'><strong>RESULT <span style={{ color: "red" }}> CLASS: X</span></strong></h4><br></br>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Sl No.</th>
                    <th scope='col'>YEAR </th>
                    <th scope="col">NO. OF REGISTERED STUDENTS</th>
                    <th scope='col'>NO. OF STUDENTS PASSED </th>
                    <th scope='col'>PASS PERCENTAGE
                    </th>
                    <th scope='col'>REMARKS </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>NA</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>NA</td>
                    <td>NA</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12">
              <div class="privacy-desc">
                <h4 className='pt-20'><strong>RESULT <span style={{ color: "red" }}> CLASS: XII</span></strong></h4><br></br>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Sl No.</th>
                    <th scope='col'>YEAR </th>
                    <th scope="col">NO. OF REGISTERED STUDENTS</th>
                    <th scope='col'>NO. OF STUDENTS PASSED </th>
                    <th scope='col'>PASS PERCENTAGE
                    </th>
                    <th scope='col'>REMARKS </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td >NA</td>
                    <td >NA</td>
                    <td >NA</td>
                    <td >NA</td>
                    <td >NA</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12">
              <div class="privacy-desc">
                <h4 className='pt-20'><strong>SCHOOL <span style={{ color: "red" }}> INFRASTRUCTURE</span></strong></h4><br></br>
              </div>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Sl No.</th>
                    <th scope='col'>INFORMATION </th>
                    <th colSpan="2">DETAILS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td >TOTAL CAMPUS AREA OF THE SCHOOL (IN SQUARE MTR)*</td>
                    <td colSpan="2">8042.22</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td >NO. AND SIZE OF THE CLASS ROOMS (IN SQ MTR)*</td>
                    <td colSpan="2">34 & 1836</td>
                  </tr>

                  <tr>
                    <th scope="row">3</th>
                    <td >NO. AND SIZE OF LABORATORIES INCLUDING COMPUTER LABS
                      (IN SQ MTR)*</td>
                    <td colSpan="2" > 06 & 324</td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td >INTERNET FACILITY*</td>
                    <td colSpan="2" > YES </td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td >NO. OF GIRLS TOILETS*</td>
                    <td colSpan="2" >12</td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>NO. OF BOYS TOILETS*</td>
                    <td colSpan="2" >10</td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td >LINK OF YOUTUBE VIDEO OF THE INSPECTION OF SCHOOL
                      COVERING THE INFRASTRUCTURE OF THE SCHOOL</td>
                      <td colSpan="2" ><a class="btn btn-primary" role="button" href="https://youtu.be/csgPFc8lRps" target="_blank" >Link</a></td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td scope='row'>LINK OF YOUTUBE REVIEW VIDEO</td>
                      <td colSpan="2" ><a class="btn btn-primary" role="button" href="https://youtu.be/OAU8S9Np_4I" target="_blank" >Link</a></td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td scope='row'>LINK OF YOUTUBE VIDEO AFTER REVIEW INSPECTION</td>
                      <td colSpan="2" ><a class="btn btn-primary" role="button" href="https://youtu.be/7WJOWe77Bis?si=ePOzMsbePWSlIlrr" target="_blank" >Link</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}