import React from 'react';

export default function CopiesOfAffiliation() {
  return (
    <>
      <section id="teachers-single" className="pt-70 pb-120 gray-bg">
        <div className="container">
          <div className="row justify-content-center">
            <h2><strong> COPIES OF AFFILIATION/UPGRADATION LETTER AND RECENT EXTENSION OF AFFILIATION, IF ANY</strong></h2><br></br>
          </div>
        </div>
      </section >
    </>
  );
}