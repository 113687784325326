import React from 'react';

export default function Recommendation() {
  return (
    <>
      <section id="page-banner" className="pt-100 pb-100 bg_cover" data-overlay="8" style={{ backgroundImage: "url(assets/images/school/front.jpg)" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-banner-cont">
                <h2>Recommendations To Parents</h2>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Recommendations To Parents</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="pt-105 pb-110">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="privacy-desc">
                <h2><strong>Recommendations <span style={{ color: "red" }}> To Parents</span></strong></h2><br></br>
              </div>
              <div class="privacy-list mt-10 mb-30">
                <ul>
                  <li>Parents/Guardians are requested to extend their full cooperation to the school authorities
                    in all the matters concerning regularity, punctuality and studies of their wards to ensure
                    their wellbeing.</li>
                  <li>  They should ensure that their ward must abide by the rules of school. In case of habitual
                    late coming, irregular attendance, lack of interest in studies, repeated failure in tests and
                    examinations and any misconduct/actions detrimental to the discipline and moral of the
                    school, the school authority reserves & the right to expel the student from the school.</li>
                  <li>Parents should ensure that their ward's fee is deposited on time.</li>
                  <li> Parents should check and sign the school diary regularly and note the teacher's remark.</li>
                  <li>Parents are requested to go through the school calendar carefully and encourage their
                    ward to participate in every activity</li>
                  <li> Parent-Teacher meetings are held as per schedule. Parents are required to attend the
                    meetings along with their wards during which enquiries about the progress of the students
                    will be readily answered.</li>
                  <li>Parents are not allowed to meet their wards or teachers during class hours. However in
                    case of emergency unforeseen incident they can meet their wards or teacher concerned
                    after seeking permission from the principal.</li>
                  <li>Parents/guardian are requested to inform the school authority of any change of address /
                    telephone number so that the school records can be updated and they may be contacted in
                    case of necessity/emergency.</li>
                  <li>If parents have any grievance against any of the teacher, they are requested to meet the
                    principal and settle the matter amicably.</li>
                  <li>In each academic session a school calendar will be given to the students. All the activities
                    will be held according to the school calendar.</li>
                  <li>Parents are advised to go through the calendar & rules mentioned in the school diary
                    carefully for the details about the examination schedule and other activities.</li>
                  <li>Parents/Guardians are requested to extend their full co-operation with the school
                    authorities in observing the general rules & regulations and are further advised not to
                    meet the school staff directly.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}