import React from "react";

export default function Footer() {
    return (
        <>
            <footer id="footer-part">
                <div className="footer-top pt-40 pb-70">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-about mt-40">
                                    <div className="footer-title pb-25">
                                        <img src="assets/images/school/chanakya.jpg" alt="Logo" />
                                    </div>
                                    <ul className="mt-20">
                                        <li><a href="#"><i className="fa fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                        <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-6">
                                <div className="footer-link mt-40">
                                    <div className="footer-title pb-25">
                                        <h6>Our Service</h6>
                                    </div>
                                    <ul>
                                        <li><a href="/"><i className="fa fa-angle-right"></i>Home</a></li>
                                        <li><a href="/about-us"><i className="fa fa-angle-right"></i>About us</a></li>
                                        <li><a href="/our-vission"><i className="fa fa-angle-right"></i>Our Vission</a></li>
                                        <li><a href="/director-msg"><i className="fa fa-angle-right"></i>Director's Message</a></li>
                                        <li><a href="/admission"><i className="fa fa-angle-right"></i>Admission</a></li>
                                    </ul>

                                    <ul>
                                        <li><a href="/mandatory-disclosure"><i className="fa fa-angle-right"></i>Mondatory Disclosure</a></li>
                                        <li><a href="/gallery"><i className="fa fa-angle-right"></i>Photo Gallery</a></li>
                                        <li><a href="/recommendation"><i className="fa fa-angle-right"></i>Recommendations to Parents</a></li>
                                        <li><a href="/rules-regulations"><i className="fa fa-angle-right"></i>Rules & Regulations</a></li>
                                        <li><a href="/contact-us"><i className="fa fa-angle-right"></i>Mail us</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-address mt-40">
                                    <div className="footer-title pb-25">
                                        <h6>Contact Us</h6>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="icon">
                                                <i className="fa fa-home"></i>
                                            </div>
                                            <div className="cont">
                                                <p>Manukapura, Uruwa, Mejaroad, Prayagraj Uttar Pradesh 212302</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fa fa-phone"></i>
                                            </div>
                                            <div className="cont">
                                                <p>+91-9415141818</p>
                                                <p>+91-9415111818</p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fa fa-envelope-o"></i>
                                            </div>
                                            <div className="cont">
                                            <a href="mailto: info@cisprayagraj.com">info@cisprayagraj.com</a>
                                            <p>sudhashru@gmail.com</p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-copyright pt-10 pb-25">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <div className="copyright text-md-left text-center pt-15">
                                    <p>&copy; Copyrights 2022 C All Chanakya International school rights reserved. </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="copyright text-md-right text-center pt-15">
                                    <p>Designed by <span>VapSoft Technology Pvt Ltd</span> </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}